import * as React from "react"
import { useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useEffect } from "react"

function IndexPage ({ location, data }) {
  const [wave, setWave] = useState(false);

  const projects = data.allMdx.nodes

  useEffect(() => {
    const timer = setTimeout(() => setWave(true), 1000);
    return () => {
      clearTimeout(timer)
    }
  }, [])
  return (
  <Layout location={location}>
    <Seo title="Home" />
    <div className="welcome">
      <div>
        <h1>Hi, I'm Eloisa <span className={ wave ? "wave" : undefined}>👋</span></h1>
        <h2>I'm a Front End Developer</h2>
      </div>
      <StaticImage src="../images/eefb.png" />
    </div>
    <div className="projects">
      <h2>Projects </h2>
      <div className="grid">
        { projects.map( project => {
          return (
            <div>
             {project.slug === "quest" ? 
             <GatsbyImage alt={project.frontmatter.title} image={project.frontmatter.embeddedImagesLocal[0].childImageSharp.gatsbyImageData} /> :
              <Link to={project.slug}>
                <GatsbyImage alt={project.frontmatter.title} image={project.frontmatter.embeddedImagesLocal[0].childImageSharp.gatsbyImageData} />
              </Link>}
              {project.slug === "quest" ? 
              <h3>{project.frontmatter.title}</h3> :
              <Link to={project.slug}>
                <h3>{project.frontmatter.title}</h3>
              </Link>}
              <p>{project.frontmatter.description}</p>
              {project.slug === "quest" ? <p style={{ fontWeight: `bold`, color: `gray`}}>Coming Soon</p> : <Link to={project.slug} className="link">View Project</Link>}
            </div>
          )
        })}
      </div>
    </div>
    <div className="about">
      <h2>About Me 👩‍💻</h2>
      <div className="columns">
      <p>
        My first coding experience happened when I was a pre-teen. I wanted to have the coolest customized Friendster profile—with a sleek font style and background images—so I dove head-first into HTML and CSS. The process was so fun, I soon felt an overwhelming desire to build a website of my own. But because of a lack of beginner-friendly resources at the time (it was 2006 lol), I became frustrated and gave up. I then turned to other interests, one of which was theater.
      </p>
      <p>
        Fast forward to 2016: I graduated from <b>Ateneo de Manila University with a degree in Theater Arts</b>. However, I realized theater wasn’t really for me and so I decided to shift gears. I thought to myself, “What are the other things I was interested in?” And then I remembered how much fun it was to build something on the web and make it my own—I remembered how much I so passionately wanted to create a website. That’s when I decided to pursue coding again.
      </p>
      <p>
        Once more I leapt head-first into coding. Thanks to many more resources (it was 2017 hooray!) I self-studied HTML, CSS, and JavaScript, and after three years of freelancing, I landed a job as a Web Design Instructor teaching <b>HTML, CSS, JavaScript, jQuery, Adobe Photoshop and Adobe XD</b>.
      </p>
      <p>
        Right now, I’m a student at Avion School where I’ve been studying to become a Software Engineer learning <b>React, Ruby, and Ruby on Rails</b>. I want to build seamless, accessible web applications that make the lives of others a lot easier. With my current skill set, I’m seeking a full-time role where I can help a company achieve this goal.
      </p>
      </div>
    </div>
  </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___order, order: ASC}) {
      nodes {
        frontmatter {
          description
          title
          embeddedImagesLocal {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        slug
      }
    }
  }
 `

export default IndexPage
